import React from "react";
import Dropdown from "components/dropdown";
import { Link } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import avatar from "assets/img/avatars/default-profile.png";
import { useGetUserInfo } from "hook/user/useGetUserInfo";
import { useKeycloak } from '@react-keycloak/web';
import Swal from 'sweetalert2'

const Navbar = (props) => {
  const { data, loading, error } = useGetUserInfo();
  const { brandText } = props;
  const [darkmode, setDarkmode] = React.useState(false);
  const { keycloak } = useKeycloak();

  const handleLogout = async(e) => {
    e.stopPropagation();

    const result = await Swal.fire({
      icon: 'question',
      title: '로그아웃 하시겠습니까?',

      confirmButtonColor: '#3085d6',
      confirmButtonText: '확인',

      showCancelButton: true,
      cancelButtonColor: '#ff5136',
      cancelButtonText: '취소', 

      customClass: {
          title: 'korean2',
          confirmButton: 'korean2',
          cancelButton: 'korean2'
      }
    });

    if(result.isConfirmed){
      keycloak.logout()
    }
  }
  
  return (
    <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
      <div className="ml-[6px]">
        <div className="h-6 w-[224px] pt-3">
          <a
            className="text-sm font-bold text-navy-700 hover:underline dark:text-white dark:hover:text-white" style={{fontSize:`15px`}}
            href=" "
          >
            Page
            <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white ">
              {" "}
              /{" "}
            </span>
          </a>
          <Link
            className="text-lg font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
            to="#"
            style={{ fontSize: '15px' }}
          >
            {brandText}
          </Link>
        </div>
        <p className="mt-1.5 shrink text-[33px] capitalize text-navy-700 dark:text-white" >
          <Link
            to="#"
            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
            style={{ fontSize: '30px' }}
          >
            {brandText}
          </Link>
        </p>
      </div>

      <div className="flex justify-end ">
        {/* Darkmode */}
        <div className="relative mr-3 flex h-[55px] w-[55px] items-center justify-center rounded-full bg-white shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none cursor-pointer text-gray-600"
          onClick={() => {
            if (darkmode) {
              document.body.classList.remove("dark");
              setDarkmode(false);
            } else {
              document.body.classList.add("dark");
              setDarkmode(true);
            }
          }}
        >
          <div>
              {darkmode ? (
                <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
              ) : (
                <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
              )}
            </div>
        </div>

        <div className="relative flex items-center justify-center rounded-full bg-white shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none cursor-pointer">
          {/* Profile & Dropdown */}
          <Dropdown
            button={
              <img
                className="h-[55px] w-[55px] rounded-fullshadow-xl shadow-shadow-500"
                src={avatar}
                alt="Elon Musk"
              />
            }
            children={
              <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                <div className="p-4">
                  <div className="flex items-center gap-2">
                    <p className="korean2 text-md text-navy-700 dark:text-white">
                      {`💌 안녕하세요, ${data}님 !`}
                    </p>{" "}
                  </div>
                </div>
                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                <div className="flex flex-col p-4">
                  {/* <a href=" " className="korean2 text-md text-gray-800 dark:text-white hover:dark:text-white" >
                    프로필 설정
                  </a> */}
                  <a 
                    onClick={handleLogout}
                    className="korean2 text-md font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in">
                    로그아웃
                  </a>
                </div>
              </div>
            }
            classNames={"py-2 top-8 -left-[180px] w-max"}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
