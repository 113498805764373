import React from "react";

// Admin Imports
import Management from "views/admin/management";
import HomeDashboard from "./views/admin/home";
import CustomDashboard from "views/admin/custom";


// Icon Imports
import {
  MdHome,
  MdDashboardCustomize,
} from "react-icons/md";
import { IoMdSettings } from "react-icons/io";

const routes = [

  {
    name: <span className="korean2 font-bold" >메인 대시보드</span>,
    layout: "/admin",
    path: "home",
    icon: <MdHome className="h-6 w-6" />,
    component: <HomeDashboard />,
    roles: ["USER", "ADMIN"],
  },
  {
    name: <span className="korean2 font-bold" >커스텀 대시보드</span>,
    layout: "/admin",
    path: "custom",
    icon: <MdDashboardCustomize className="h-6 w-6" />,
    component: <CustomDashboard />,
    roles: ["USER", "ADMIN"],
  },
  {
    name: <span className="korean2 font-bold" >대시보드 관리</span>,
    layout: "/admin",
    path: "management",
    icon: <IoMdSettings className="h-6 w-6" />,
    component: <Management />,
    roles: ["ADMIN"],
  },

];
export default routes;
