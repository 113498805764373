import React, {useState} from 'react';
import Modal from 'react-modal';
import {TfiClose} from "react-icons/tfi";
import {updateDashboard} from "../../../../api/request/dashboard";
import {toast} from "react-hot-toast";
import AceEditor from "react-ace";
import Swal from 'sweetalert2'

const UpdateDashboardModal = ({ dashboardId, isOpen, onRequestClose, title, detail, code}) => {
    const [editedTitle, setEditedTitle] = useState(title);
    const [editedDetail, setEditedDetail] = useState(detail);
    const [editedCode, setEditedCode] = useState(code);
    const [isTitleValid, setIsTitleValid] = useState(true);
    const [isDetailValid, setIsDetailValid] = useState(true);
    const [isCodeValid, setIsCodeValid] = useState(true);


    const handleSave = async ( e, dashboardId ) => {
        e.stopPropagation();

        if (!editedTitle || !editedCode ) {
            toast.error('제목과 임베드 코드는 필수값입니다');
            return;
        }

        if (!isTitleValid || !isDetailValid || !isCodeValid ) {
            toast.error('모든 입력 필드를 올바르게 작성해주세요.');
            return;
        }


        try{
            const result = await Swal.fire({
                title: '수정 내용을 저장하시겠습니까?',
                icon: 'question',
                
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#ff5136', 
                confirmButtonText: '확인', 
                cancelButtonText: '취소', 

                customClass: {
                    title: 'korean2', 
                    content: 'korean2',  
                    confirmButton: 'korean2', 
                    cancelButton: 'korean2' 
                },
                
                reverseButtons: true, // 버튼 순서 거꾸로
            });

            if(result.isConfirmed){
                // 임베드 코드에서 요소 추출
                const {width, height, url} = parseHtml(editedCode);

                // 코드 인코딩
                const encodedValue = btoa(editedCode);

                await updateDashboard(dashboardId,{
                    title: editedTitle,
                    detail: editedDetail,
                    url: url,
                    code: encodedValue,
                    width: width,
                    height: height
                });
                
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center-center',
                    showConfirmButton: false,
                    timer: 1000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                })
              
                await Toast.fire({
                    icon: 'success',
                    title: '대시보드 수정 내용을 저장했습니다',
                    customClass: {
                        title: 'korean2',  
                    },
                })

                onRequestClose();

            }
        } catch (e){
            toast.error(e.message);
        }
    };

    const handleTitleChange = (e) => {
        const value = e.target.value;
        setEditedTitle(value);
        setIsTitleValid(value.length <= 20);
    };

    const handleDetailChange = (e) => {
        const value = e.target.value;
        setEditedDetail(value);
        setIsDetailValid(value.length <= 80);
    };


    const handleCodeChange = (e) => {
        const value = e.target.value;
        setEditedCode(value);
    
        // 정규식을 이용하여 width, height, src 속성 검증
        const codeRegex = /<iframe\s+width="([^"]*)" height="([^"]*)" src="([^"]+)">\s*<\/iframe>/;
        setIsCodeValid(codeRegex.test(value));
    
        // src 속성의 값 검증
        const urlMatch = value.match(/src="([^"]+)"/);
        if (urlMatch) {
            const url = urlMatch[1];
            const urlRegex = /^https?:\/\//i;
            setIsCodeValid(urlRegex.test(url));
        } else {
            setIsCodeValid(false);
        }
    };
    

    const parseHtml = () => {
        const widthMatch = editedCode.match(/width="(\d+)"/);
        const heightMatch = editedCode.match(/height="(\d+)"/);
        const urlMatch = editedCode.match(/src="([^"]+)"/);
    
        return {
            width: widthMatch ? widthMatch[1] : '',
            height: heightMatch ? heightMatch[1] : '',
            url: urlMatch ? urlMatch[1] : ''
        };
    };
    
    const handleCloseModal = () => {

        // 사용자가 수정한 내용 저장하지 않고 모달 닫는 것 컨펌 
        if (title !== editedTitle || detail !== editedDetail || code !== editedCode) {
            Swal.fire({
                
                icon: 'warning',
                title: '저장하지 않고 나가시겠습니까?',

                confirmButtonColor: '#3085d6',
                confirmButtonText: '확인',

                showCancelButton: true,
                cancelButtonColor: '#ff5136',
                cancelButtonText: '취소', 

                customClass: {
                    title: 'korean2',
                    confirmButton: 'korean2',
                    cancelButton: 'korean2'
                }

            }).then(result => {
                if (result.isConfirmed) {
                    onRequestClose(); 
                }
            });

        } else {
            onRequestClose(); 
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Edit Dashboard"
            ariaHideApp={false}
            className="modal korean2"
            overlayClassName="modal-overlay"
            shouldCloseOnOverlayClick={false}
        >
            <div className="flex justify-between pt-10 pl-10 p-6 bg-blue-100">
                <h2 className="text-2xl font-bold">대시보드 수정</h2>
                <button onClick={handleCloseModal} className="modal-x-button text-3xl"><TfiClose /></button>
            </div>
            <div className="modal-content flex flex-col m-4 pl-10 pr-10">
                <label>
                <h2 className="text-lg font-bold mt-3 m-1">
                    <span className="text-black">제목</span>
                    <span className="text-red-500"> *</span>
                </h2>
                    <input
                        type="text"
                        value={editedTitle}
                        onChange={handleTitleChange}
                        className={`modal-input ${!isTitleValid ? 'input-error' : ''}`}
                        maxLength={21}
                    />
                    {!isTitleValid && <span className="text-red-500 text-sm right-0 top-0">20자 이내로 작성해주세요</span>}
                </label>
                <label>
                    <h2 className="text-lg font-bold mt-5 m-1 pb-2">
                        <span className="text-black">임베드 코드</span>
                        <span className="text-red-500"> *</span>
                    </h2>
                    <input
                        type="text"
                        value={editedCode}
                        onChange={handleCodeChange}
                        className={`modal-input ${!isCodeValid ? 'input-error' : ''}`}
                        maxLength={700}
                    />
                    {!isCodeValid && <span className="text-red-500 text-sm right-0 bottom-0">올바른 형식의 임베드 코드가 아닙니다</span>}               
                </label>
                <label>
                    <h2 className="text-lg font-bold mt-5 m-1">설명</h2>
                    <input
                        type="text"
                        value={editedDetail}
                        onChange={handleDetailChange}
                        className={`modal-input ${!isDetailValid ? 'input-error' : ''}`}
                        maxLength={81}
                    />
                    {!isDetailValid && <span className="text-red-500 text-sm right-0 bottom-0">80자 이내로 작성해주세요</span>}
                </label>
            </div>

            <div className="modal-footer">
                <button onClick= {handleCloseModal} className="modal-close-button hover:bg-blue-400 font-bold">닫기</button>
                <button onClick= {(e) => handleSave(e,dashboardId)} className="modal-save-button hover:bg-blue-400 font-bold">저장</button>
            </div>

        </Modal>
    );
}

export default UpdateDashboardModal;
