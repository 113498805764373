import React from 'react';
import { Helmet } from 'react-helmet';
import { config } from "../../../../Constants";

const frontURL = config.self.PROD_URL;
const backURL = config.api.PROD_URL;

const ThumbnailIframe = ({url, onLoad}) => {

    const handleClick = () => {
        window.location.href = url.replace('/sn/embed', '')
    };

    return(
        <div className="relative w-full h-full" onClick={handleClick}> 
        {/* 퀵사이트 임베디드 Iframe 호출부 */}
        <Helmet>
            <meta
                httpEquiv="Content-Security-Policy"
                content={`
                img-src 'self' https://* ${frontURL};
                connect-src 'self' ${backURL} wss://bi-dashboard.customer.wjcloud.co.kr:3000;
                frame-src 'self' https://ap-northeast-2.quicksight.aws.amazon.com/
                `}
            />
        </Helmet>
        <iframe
            title="AWS QuickSight Dashboard"
            width="100%"
            height="100%"
            className={`border-none`}
            src= {url}
            sandbox="allow-scripts allow-same-origin allow-popups"
            onLoad={onLoad}
            object-fit= "contain"
        ></iframe>
    </div>
    );

};

export default ThumbnailIframe;