import { useState } from 'react';
import styled from 'styled-components';
import {toast} from "react-hot-toast";
import { updateDashboardStatus } from 'api/request/dashboard';

export const Toggle = ({dashboardId, initialStatus}) => {
  const [isEnabled, setIsEnabled] = useState(initialStatus === "ENABLED");

  const toggleHandler = async (e) => {
    e.stopPropagation();

    const newStatus = isEnabled ? "DISABLED" : "ENABLED";
    setIsEnabled(!isEnabled);
    
    try {
      await updateDashboardStatus(dashboardId, newStatus);
      if (newStatus === "ENABLED") {
        toast.success('대시보드를 활성화했습니다');
      }
    } catch (error) {
      toast.error(error.message);
      // 오류 발생시 상태 원상복귀
      setIsEnabled(isEnabled);
    }
  };

  return (
    <>
      <ToggleContainer title="대시보드 활성화" onClick={toggleHandler}>
        <div className={`toggle-container ${isEnabled ? "toggle--checked" : ""}`} />
        <div className={`toggle-circle ${isEnabled ? "toggle--checked" : ""}`} />
      </ToggleContainer>
      <div className={isEnabled ? 'ON' : 'OFF'}></div>
    </>
  );
};

const ToggleContainer = styled.div`
  position: relative;
  cursor: pointer;

  > .toggle-container {
    width: 50px;
    height: 24px;
    border-radius: 30px;
    background-color: rgb(233,233,234);
  } > .toggle--checked {
    background-color: rgb(0,200,102);
    transition : 0.5s
  }

  > .toggle-circle {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: rgb(255,254,255);
    transition : 0.5s
  } >.toggle--checked {
    left: 27px;
    transition : 0.5s
  }
`;
