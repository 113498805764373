import {axiosWithToken} from "../api";
import {VERSION} from "../../Constants";

// 유저 정보 조회
const getUserInfo = () => {
    return axiosWithToken.get(`/api/${VERSION}/auth/info`)
}

export {
    getUserInfo,
}