import Card from "components/card";
import {toast, Toaster} from 'react-hot-toast';
import { FaLink, FaEdit, FaTrash } from 'react-icons/fa';
import {deleteDashboard} from "../../../../api/request/dashboard";
import {useState} from "react";
import UpdateDashboardModal from "./UpdateDashboardModal";
import {Toggle} from "./StatusToggle";
import ThumbnailIframe from "./ThumbnailIframe";
import Swal from 'sweetalert2'

const DashboardCard = ({ id, url, title, detail, manager, createdAt, status, code, onLoad }) => {

    const [isModalOpen, setIsModalOpen] = useState(false);


    /* * * 삭제 버튼 핸들러 * * */
    const handleDeleteDashboard = async (e, dashboardId) => {
        e.stopPropagation();
        try {

            const result = await Swal.fire({
                title: '대시보드를 삭제하시겠습니까?',
                icon: 'warning',
                
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#ff5136', 
                confirmButtonText: '확인', 
                cancelButtonText: '취소', 

                customClass: {
                    title: 'korean2', 
                    content: 'korean2',  
                    confirmButton: 'korean2', 
                    cancelButton: 'korean2' 
                },
                
                reverseButtons: true, // 버튼 순서 거꾸로
            });

            if(result.isConfirmed){
                await dashboardSafeDelete(dashboardId);
            }

        } catch (e) {
            console.error(e);
        }
    };

    const dashboardSafeDelete = async (dashboardId) => {
        try {
            await deleteDashboard(dashboardId);

            const Toast = Swal.mixin({
                toast: true,
                position: 'center-center',
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
          
            await Toast.fire({
                icon: 'success',
                title: '대시보드가 삭제되었습니다',
                customClass: {
                    title: 'korean2',  
                },
            })

            window.location.reload();


        } catch (e) {
            toast.error(e.message);
        }
    };


    /* * * 수정 버튼 핸들러(모달창) * * */

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        window.location.reload();
    };


    /* * * * 링크 연결 핸들러 * * * */

    const handleLinkClick = (e) => {
        e.stopPropagation();

        if (!url) {
            console.error('URL is not defined');
            return;
        }
        const newUrl = url.replace('/sn/embed', '');

        window.open(newUrl);

    };



    return (
        <>
            <Card extra="p-5 h-[500px]">
                <Toaster/>
                <div className="flex flex-col justify-center">
                    <div >
                        <div className="flex flex-col justify-between mt-2 mx-3">
                            <Toggle
                                dashboardId={id} 
                                initialStatus={status}                           
                            />
                            <div className="card-buttons mt-3">
                                <button title="퀵사이트 바로 가기" className="card-button" onClick={(e) => handleLinkClick(e) }>
                                    <FaLink className="dark:text-white" />
                                </button>
                                <button title="수정" className="card-button" onClick={openModal}>
                                    <FaEdit className="dark:text-white"/>
                                </button>
                                <button title="삭제" className="card-button" onClick={(e) => handleDeleteDashboard(e, id)}>
                                    <FaTrash className="dark:text-white"/>
                                </button>
                            </div>
                        </div>
                        <div className="mt-4 mb-2 mx-3 p-1 pb-1">
                            <p className="mb-1 text-[32px] font-bold text-navy-700 dark:text-white overflow-hidden line-clamp-1">
                                {title}{" "}
                            </p>
                            <p className="text-base font-medium h-[53px] text-gray-700 overflow-hidden line-clamp-2">
                                {detail}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="px-3 h-full w-full">
                    {/* <QuickSightPreview url={url} /> */}
                    <ThumbnailIframe className="px-3" url={url} onLoad={onLoad} />
                </div>

                <div className="flex items-end justify-between mt-5">
                    <p className="text-[15px] font-bold leading-4 text-gray-700">
                        {createdAt}
                    </p>
                    <p className="text-[15px] font-bold leading-4 text-gray-700">
                        {manager}
                    </p>
                </div>
            </Card>

            <UpdateDashboardModal
                dashboardId={id}
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                title={title}
                detail={detail}
                code={code}
            />
        </>
    );
};

export default DashboardCard;
