import React from 'react';
import Error from '../assets/Error.gif';

export default () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '70vh', paddingTop: '5vh' }}>
            <label className="korean2 mb-1 dark:text-white" style={{ fontSize: '21px' }}>
                오류가 발생했습니다. 다시 시도해주세요
            </label>
            <img src={Error} alt="로딩중" style={{ width: '7%' }} />
        </div>
    );
};
