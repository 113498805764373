import {axiosWithToken} from "../api";
import {VERSION} from "../../Constants";


// 대시보드 조회(무한스크롤)
const getInfiniteDashboard = page => {
    return axiosWithToken.get(`/api/${VERSION}/dashboard`, page )
}

// 대시보드 조회(일반)
const getDashboard = () => {
    return axiosWithToken.get(`/api/${VERSION}/dashboard` )
}

// 대시보드 검색
const searchDashboard = (filter, keyword) => {
    return axiosWithToken.get(`/api/${VERSION}/dashboard/search`,{
        params: {
            filter: filter,
            keyword: keyword
        }
    })
}

// 대시보드 등록하기
const saveDashboard = dashboardRequest => {
    const { url, title, detail, code, width, height } = dashboardRequest;
    return axiosWithToken.post(`api/${VERSION}/dashboard`,{
        url: url,
        title: title,
        detail: detail,
        code: code,
        width: width,
        height: height
    });
}

// 대시보드 수정하기
const updateDashboard = (dashboardId, dashboardRequest) => {
    const { url, title, detail, code, width, height } = dashboardRequest;
    return axiosWithToken.put(`api/${VERSION}/dashboard/${dashboardId}`,{
        url: url,
        title: title,
        detail: detail,
        code: code,
        width: width,
        height: height
    });
}

// 대시보드 상태 수정하기
const updateDashboardStatus = (dashboardId, status) => {
    return axiosWithToken.patch(`api/${VERSION}/dashboard/status/${dashboardId}`,{
        status: status
    });
}

// 대시보드 사이즈 수정하기
const updateDashboardSize = (iframeSizes) => {
    return axiosWithToken.post(`api/${VERSION}/dashboard/resize`, iframeSizes);
}

// 대시보드 삭제하기
const deleteDashboard = dashboardId => {
    return axiosWithToken.delete(`api/${VERSION}/dashboard/${dashboardId}`)
}

export {
    getInfiniteDashboard,
    getDashboard,
    saveDashboard,
    updateDashboard,
    deleteDashboard,
    searchDashboard,
    updateDashboardStatus,
    updateDashboardSize,
};
