import React, { useRef, useEffect, useState } from 'react';
import { css } from '@emotion/css';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';

const containerStyle = css`
  width: 100%;
  height: 500px; 
  border: 1px solid #ccc;
  margin: 2px;
  overflow: auto;
  position: relative;
`;

const iframeStyle = css`
  width: 100%;
  height: 100%;
  border: none; 
`;

interface Props {
  url: string;
  initialWidth: number;
  initialHeight: number;
  id: number;
  onResize: (id: number, width: number, height: number) => void;
  onLoad: () => void;
}

const CustomIframe = ({ id, url, initialWidth, initialHeight, onLoad, onResize }: Props) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [parentWidth, setParentWidth] = useState(window.innerWidth);
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);

  useEffect(() => {
    const handleResize = () => setParentWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    setWidth(initialWidth);
    setHeight(initialHeight);
  }, [initialWidth, initialHeight]);

  return (
    <ResizableBox
      className={containerStyle}
      width={width}
      height={height}
      minConstraints={[200, 300]} 
      maxConstraints={[parentWidth, 800]} 
      onResize={(e, data) => {
        setWidth(data.size.width);
        setHeight(data.size.height);
        onResize(id, data.size.width, data.size.height);
      }}
    >
      <iframe
        className={iframeStyle}
        ref={iframeRef}
        src={url}
        onLoad={onLoad}
      />
    </ResizableBox>
  );
};

export default CustomIframe;

