import { useEffect, useState } from "react";
import {getDashboard, searchDashboard} from "../../api/request/dashboard";


const useSearchDashboard = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const doSearch = async (filter, keyword) => {
        try {
            setLoading(true);
            const res = await searchDashboard(filter, keyword);
            const data = res.data.data;
            setData(data);

            return data;
        } catch (e) {
            setError(e.message || '에러가 발생했습니다.');
        } finally {
            setLoading(false);
        }

        return data;
    };

    return {data, loading, error, doSearch };

}

export { useSearchDashboard }