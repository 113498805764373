import {axiosWithToken} from "../api";
import {VERSION} from "../../Constants";

// 홈화면 대시보드 조회
const getHomeDashboard = () => {
    return axiosWithToken.get(`/api/${VERSION}/home`)
}

export {
    getHomeDashboard,
}