import Axios from 'axios';
import { getAuthToken } from '../util/TokenUtil';
import { config } from "../Constants";

const baseURL= config.api.PROD_URL;
const quicksightURL = 'https://quicksight.aws.amazon.com/sn/start';

// -- Axios
const axios = Axios.create({
    baseURL,
    withCredentials: true,
});

const axiosWithToken = Axios.create({
    baseURL,
    withCredentials: true,
}) 

const awsLogin = Axios.create({
    quicksightURL
})

axiosWithToken.interceptors.request.use(config => {
    const accessToken = getAuthToken();
    if (config.headers && accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
});

awsLogin.interceptors.request.use(config => {
    const accessToken = getAuthToken();
    if (config.headers && accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
});

export { baseURL, axios, axiosWithToken };