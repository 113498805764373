import { useEffect, useState } from "react";
import { getDashboard } from "../../api/request/dashboard";

const useGetDashboard = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            const res = await getDashboard();
            const data = res.data.data;
            setData(data);
        } catch (e) {
            setError(e.message || '에러가 발생했습니다.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const reFetch = async () => {
        await fetchData();
    };

    return {data, loading, error, reFetch};

};

export { useGetDashboard }