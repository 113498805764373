
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { config } from "../../../../Constants";
import HomeLoading from 'util/loading/HomeLoading';

const frontURL = config.self.PROD_URL;
const backURL = config.api.PROD_URL;

const IframeCard = () => {
    const [isLoading, setIsLoading] = useState(true);

    const handleIframeLoad = () => {
        setIsLoading(false);
    };

    return(
        <div>
            <div> 
                {/* 퀵사이트 임베디드 Iframe 호출부 */}
                <Helmet>
                    <meta
                        httpEquiv="Content-Security-Policy"
                        content={`
                        img-src 'self' https://* ${frontURL} data:;
                        connect-src 'self' ${backURL} wss://bi-dashboard.customer.wjcloud.co.kr:3000;
                        frame-src 'self' https://ap-northeast-2.quicksight.aws.amazon.com/ ${frontURL} ;
                        `}
                    />
                </Helmet>
                {isLoading && <HomeLoading />}
                <iframe
                    title="AWS QuickSight Dashboard"
                    height="2000"
                    width= "1500"
                    className={`pt-5 border-none ${isLoading ? 'hidden' : ''}`}
                    src= "https://ap-northeast-2.quicksight.aws.amazon.com/sn/embed/share/accounts/365988561824/dashboards/b59b8099-0950-4345-b786-5e1ec6f8f77d?directory_alias=wj-bi-quicksight"
                    sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
                    onLoad={handleIframeLoad}
                ></iframe>
            </div>
        </div>
    );

};

export default IframeCard;