export const config = {
    keycloak: {
        PROD_URL: "https://bi-dashboard.eks-devops.wjcloud.co.kr/",
        LOCAL_URL: "http://localhost:8090",
        REALM: "aws-springboot",
        CLIENT: "watch-bi"
    },
    api: {
        PROD_URL: "https://bi-dev.customer.wjcloud.co.kr/",
        LOCAL_URL: "http://localhost:8077"
    },
    self:{
        PROD_URL: "https://bi-dashboard.customer.wjcloud.co.kr/",
        LOCAL_URL: "http://localhost:3000"
    }
}
 
export const VERSION = 'v1';