// CustomDashboardLoading.js
import React from 'react';
import {Background, LoadingText} from '../LoadingStyles';
import Spinner from '../../assets/Spinner.gif';

export default () => {
    return (
        <Background>
            <LoadingText className="korean2 mb-3" style={{ fontSize: '24px' }}>커스텀 대시보드를 불러오고 있습니다</LoadingText>
            <img src={Spinner} alt="로딩중" width="8%" />
        </Background>
    );
};
