import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import AdminLayout from "layouts/admin";
import { config } from "./Constants";
import PrivateRoute from "./util/PrivateRoute";
import useAuth from "./util/UseAuth";
import Loading from "./util/loading/Loading";

const keycloak = new Keycloak({
  url: `${config.keycloak.PROD_URL}`,
  realm: `${config.keycloak.REALM}`,
  clientId: `${config.keycloak.CLIENT}`
});

const initOptions = {
  onLoad: 'login-required',
  checkLoginIframe: false,
  pkceMethod: 'S256'
};

const openQuickSightInNewTab = () => {
  const url = "https://bi-dashboard.eks-devops.wjcloud.co.kr/realms/aws-springboot/clients/urn:amazon:webservices/redirect";
  const newTab = window.open(url, "_blank");
  setTimeout(() => {
    if (newTab) {
      newTab.close();
      window.location.reload();
    }
  }, 1000);
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  const handleOnEvent = async (event, error) => {
    switch (event) {
      case 'onAuthLogout':
        keycloak.logout();
        break;
      case 'onAuthRefreshError':
        keycloak.logout();
        break;
      case 'onAuthSuccess':
        const token = keycloak.token;
        useAuth.getState().login(token);

        if (!sessionStorage.getItem("quickSightLoginDone")) {
          openQuickSightInNewTab();
          sessionStorage.setItem("quickSightLoginDone", "true");
        }

        break;
      default:
        break;
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={initOptions}
      LoadingComponent={Loading}
      onEvent={(event, error) => handleOnEvent(event, error)}
    >
      <ScrollToTop /> 
      <Routes>
        <Route path="admin/*" element={<PrivateRoute roles={['USER', 'ADMIN']}><AdminLayout /></PrivateRoute>} />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </ReactKeycloakProvider>
  );
}

export default App;
