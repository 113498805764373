
import React from 'react';
import IframeCard from './components/IframeCard';

const HomeDashboard = () => {

    return (
        <div>
            <div className="korean2 mt-3 h-full ">
                <IframeCard />
            </div>
        </div>

    );

};

export default HomeDashboard;