import React, { useEffect, useState, useRef } from 'react';
import { useGetHomeDashboard } from 'hook/home/useGetHomeDashboard';
import Loading from "../../../util/loading/CustomDashboardLoading";
import Error from "../../../util/Error";
import CustomIframe from './components/CustomIframe.tsx';
import {toast, Toaster} from "react-hot-toast";
import { updateDashboardSize } from 'api/request/dashboard';
import Swal from 'sweetalert2'


const CustomDashboard = () => {
    const { data, loading, error } = useGetHomeDashboard();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedCount, setLoadedCount] = useState(0);
    const [iframeSizes, setIframeSizes] = useState({});
    const iframesRefs = useRef([]);

    useEffect(() => {
        if (data && data.content && data.content.length > 0) {
            setIsLoading(true);
            setLoadedCount(0);
        }
    }, [data]);

    useEffect(() => {
        if (data && data.content && loadedCount === data.content.length) {
            setIsLoading(false);
        }
    }, [loadedCount, data]);

    if (error) {
        return <Error />;
    }

    if (loading) {
        return <Loading />;
    }

    const handleIframeLoad = () => {
        setLoadedCount((prevCount) => prevCount + 1);
    };

    const handleResize = (id, width, height) => {
        setIframeSizes((prevSizes) => ({
            ...prevSizes,
            [id]: { width, height }
        }));
    };

    const handleSave = async (e) => {
        e.stopPropagation();

        const payload = {
            iframes: iframeSizes
        };

        try{
            await updateDashboardSize(payload);
            const Toast = Swal.mixin({
                toast: true,
                position: 'center-center',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
          
            await Toast.fire({
                icon: 'success',
                title: '커스텀 대시보드를 저장했습니다',
                customClass: {
                    title: 'korean2',  
                },
            })


        } catch(e){
            toast.error(e.message);
        }
    };

    const handleReset = async () => {

        const result = await Swal.fire({
            title: '정말 초기화할까요?',
            icon: 'question',
            
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#ff5136', 
            confirmButtonText: '확인', 
            cancelButtonText: '취소', 

            customClass: {
                title: 'korean2', 
                content: 'korean2',  
                confirmButton: 'korean2', 
                cancelButton: 'korean2' 
            },
            
            reverseButtons: true, // 버튼 순서 거꾸로
        });

        if(result.isConfirmed){
            window.location.reload();
        }
    } 

    return (
        <div className="mt-9 border-4 border-gray-150 p-6 pb-20 rounded-xl bg-gray-200 dark:bg-navy-700 dark:border-none">
            {isLoading && <Loading />}
            {data && data.content && (
                <div className="korean2 flex flex-wrap gap-1">
                    {data.content.map((item, index) => (
                        <CustomIframe key={index} id={item.id} url={item.url} initialWidth={item.width}
                            initialHeight={item.height} onLoad={handleIframeLoad} onResize={handleResize}/>
                    ))}
                </div>
            )}
            <Toaster />
            <div className="flex flex-col fixed bottom-8 right-9">
                <button 
                    title="초기화" 
                    className="korean2 bg-red-400 text-white mb-3 text-xl py-5 px-6 rounded-full shadow-lg hover:bg-red-700 transition-colors duration-300"
                    onClick={handleReset}>
                    초기화
                </button>
                <button
                    title="저장하기" 
                    className="korean2 bg-blue-500 text-white text-xl py-5 px-6 rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-300"
                    onClick={handleSave}>
                    저장하기
                </button>
            </div>
        </div>
    );
};

export default CustomDashboard;
