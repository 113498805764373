import {useGetDashboard} from "../../../hook/dashboard/useGetDashboard";
import DashboardCard from "./components/DashboardCard";
import Loading from "../../../util/loading/Loading";
import Error from "../../../util/Error"
import {FaPlus, FaSearch} from "react-icons/fa";
import {useEffect, useState} from "react";
import NewDashboardModal from "./components/NewDashboardModal";
import {useSearchDashboard} from "../../../hook/dashboard/useSearchDashboard";
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'

const Management = () => {
    const { data: dashboardData, loading, error, reFetch } = useGetDashboard();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searchFilter, setSearchFilter] = useState('all');
    const [searchedData, setSearchedData] = useState(null); // 검색 결과 저장
    const { data: searchData, loading: searchLoading, error: searchError, doSearch } = useSearchDashboard();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedCount, setLoadedCount] = useState(0);
    const { keycloak } = useKeycloak();
    const navigate = useNavigate();

      useEffect(() => {
        if (!keycloak.authenticated || !keycloak.hasRealmRole('ADMIN')) {
            setIsLoading(true);
            setLoadedCount(0);
            Swal.fire({
                icon: 'error',
                title: '접근 권한이 없습니다',
                text: '접근 권한은 관리자에게 문의하세요',
                confirmButtonColor: '#3085d6',
                confirmButtonText: '확인',
                backdrop: `
                rgba(255, 255, 255, 1)
                left top
                no-repeat
                `,

                customClass: {
                    title: 'korean2',
                    text: 'korean2',
                    confirmButton: 'korean2',
                    cancelButton: 'korean2'
                }

            }).then(() => {
                navigate('/admin/home');
            });
        }
    }, [keycloak, navigate]);
    


    useEffect(() => {
        if (dashboardData && dashboardData.content) {
            setIsLoading(true);
            setLoadedCount(0);
        }
    }, [dashboardData]);

    const handleIframeLoad = () => {
        setLoadedCount((prevCount) => prevCount + 1);
    };

    useEffect(() => {
        if (dashboardData && dashboardData.content && loadedCount === dashboardData.content.length) {
            setIsLoading(false);
        }
    }, [loadedCount, dashboardData]);

    const searchDashboard = async (e) => {
        e.stopPropagation();
        if(!searchKeyword) setSearchedData(dashboardData);
        else{
            const searchResult = await doSearch(searchFilter, searchKeyword);
            setSearchedData(searchResult)
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        reFetch();
    };

    if(error) { return <p><Error/></p>; }

    if (loading) { return <p><Loading/></p>; }


    const displayedData = searchedData || dashboardData;


    return (
        <div>
            {isLoading && <Loading />}
            <div className="flex items-center justify-center mt-5 mb-6 w-full">
                <div className="dashboard-search mr-2">
                    <div className="flex items-center rounded-lg bg-white px-4 py-3">

                        {/* 검색 아이콘 */}
                        <div className="flex-shrink-0 pr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600"
                                 fill="none"
                                 viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M15.5 15.5l5.5 5.5"/>
                                <circle cx="10.5" cy="10.5" r="7.5"/>
                            </svg>
                        </div>

                        {/* 필터 드롭다운 */}
                        <select
                            title="검색 필터" 
                            value={searchFilter}
                            onChange={e => setSearchFilter(e.target.value)}
                            className="korean2 bg-transparent focus:outline-none border-none p-1 m-0 mr-2" style={{ fontSize: '21px' }}>
                            <option value="all">전체</option>
                            <option value="title">제목</option>
                            <option value="content">내용</option>
                            <option value="manager">관리자</option>
                        </select>


                        {/* 검색 입력란 */}
                        <input
                            type="text"
                            placeholder="검색"
                            value={searchKeyword}
                            onChange={e => setSearchKeyword(e.target.value)}
                            className="korean2 bg-transparent focus:outline-none w-full"  style={{ fontSize: '18px' }}
                        />

                    </div>
                </div>

                {/* 검색 버튼 */}
                <button title="검색" className="new-dashboard-button korean2 font-bold px-4 py-4 text-center mr-2"
                        onClick={searchDashboard}>
                    <FaSearch/>
                </button>



                {/* 대시보드 신규 등록 버튼 */}
                <button title="대시보드 신규 등록" className="new-dashboard-button korean2 font-bold px-4 py-4 text-center" onClick={openModal}>
                    <FaPlus/>
                </button>

            </div>

            {displayedData && displayedData.content && (
                <div
                    className="korean2 mt-7 grid grid-cols-1 gap-7 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-3">
                    {displayedData.content.map((item, index) => (
                        <DashboardCard key={index} id={item.id} url={item.url} title={item.title} code={atob(item.code)} detail={item.detail}
                                       manager={item.manager} createdAt={item.createdAt} status={item.status} onLoad={handleIframeLoad} />
                    ))}
                </div>
            )}

            <NewDashboardModal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
            />
        </div>
    );
};

export default Management;
